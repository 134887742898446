import { List, ListItem, ListItemText, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const AzureUserDetails = ({ user }) => {
  return (
    <>
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Name:</strong> {user?.displayName || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>SurName:</strong> {user?.surname || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Azure ID:</strong> {user?.azureId || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Department:</strong> {user?.department || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Principal Name:</strong>{" "}
                {user?.user?.rincipalName || "N/A"}
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Account Enabled:</strong>{" "}
                {user?.accountEnabled || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Preferred Language:</strong>{" "}
                {user?.preferredLanguage || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Job Title:</strong> {user?.jobTitle || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Mail:</strong> {user?.mail || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Office Location:</strong>{" "}
                {user?.officeLocation || "N/A"}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Synced At:</strong>{" "}
                {moment(user?.synced_at).format("DD/MM/YYYY HH:mm:ss")}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </>
  );
};

export default AzureUserDetails;
