import React, { useState } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormLogo from "./components/FormLogo";
import SignInEmailCheck from "./components/SignInEmailCheck";
import SignInForm from "./components/SignInForm";
import AuthenticationPage from "./components/AuthenticationPage";
import { Button, Grid, Link, Paper, Stack } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { TiVendorMicrosoft } from "react-icons/ti";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#233141",
    },
  },
});

const CustomSignIn = () => {
  const { setAzureRedirectURI } = useUserStore((state) => state);

  const [activeForm, setActiveForm] = useState({
    isEmailActive: true,
    isSignInActive: false,
  });
  const [tenantName, setTenantName] = useState("");
  const [realmName, setRealmName] = useState("");
  const [redirectURI, setRedirectURI] = useState("");

  const [email, setEmail] = useState("");

  const setEmailFormActive = () => {
    setActiveForm({
      isEmailActive: true,
      isSignInActive: false,
      isFromSSO: null,
    });
  };

  const setPasswordFormActive = () => {
    setActiveForm({
      isEmailActive: false,
      isSignInActive: true,
      isFromSSO: null,
    });
  };

  const setActiveSSO = (value) => {
    setActiveForm({
      isEmailActive: false,
      isSignInActive: false,
      isFromSSO: value,
    });
  };

  const azureInitAndLogin = async () => {
    myLocalStorage.setItem("email", email.toLowerCase().trim());
    myLocalStorage.setItem("realm", "whiteswan_tenants_legacy");
    console.log(redirectURI);
    setAzureRedirectURI(redirectURI);
    window.location.href = redirectURI;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <AuthenticationPage theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Paper
            elevation={5}
            sx={{
              maxHeight: 450,
              minHeight: 450,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: 3,
              borderRadius: 5,
            }}
          >
            <Stack
              spacing={2}
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: "50px !important",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  spacing={0.8}
                >
                  <LockOutlinedIcon
                    sx={{
                      color: "red",
                      fontSize: "2rem",
                    }}
                  />
                  <Typography
                    component="h1"
                    variant="h1"
                    sx={{
                      wordBreak: "break-word",
                      fontSize: "24px",
                      fontWeight: "bold",
                      letterSpacing: ".011em",
                      lineHeight: "normal",
                    }}
                  >
                    Sign in
                  </Typography>
                </Stack>
              </Box>
              <SignInEmailCheck
                togleActiveForm={setPasswordFormActive}
                toggleEmailActive={setEmailFormActive}
                setRealmName={setRealmName}
                setTenantName={setTenantName}
                email={email}
                setEmail={setEmail}
                activeForm={activeForm}
                setActiveSSO={setActiveSSO}
                setRedirectURI={setRedirectURI}
              />
              {activeForm.isSignInActive ? (
                <SignInForm
                  togleActiveForm={setEmailFormActive}
                  email={email}
                  tenantName={tenantName}
                  realmName={realmName}
                  setRealmName={setRealmName}
                />
              ) : null}
            </Stack>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              {activeForm.isFromSSO !== "azureSSO" ? (
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1" align="center">
                    Don't have an account?{" "}
                    <Link
                      href="/signUp"
                      variant="body2"
                      sx={{ fontWeight: "bold" }}
                    >
                      Sign up!
                    </Link>
                  </Typography>
                </Grid>
              ) : null}
              {activeForm.isFromSSO === "azureSSO" ? (
                <Grid item xs={12} sm={12}>
                  <Stack spacing={2} alignItems="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      sx={{
                        padding: "10px 16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                      startIcon={<TiVendorMicrosoft />}
                      onClick={azureInitAndLogin}
                    >
                      <Typography variant="body1">
                        Sign in with Microsoft SSO
                      </Typography>
                    </Button>
                  </Stack>
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </Container>
      </AuthenticationPage>
    </ThemeProvider>
  );
};

export default CustomSignIn;
