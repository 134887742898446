import { Box, Button, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { NGROK } from "../../../../APIs";

const AutoAgentUpgrade = () => {
  const [enableDialog, setEnableDialog] = useState(false);
  const location = useLocation();
  const locationState = location?.state;
  const profile =
    locationState?.defaultProfile || myLocalStorage.getItem("selectedProfile");
  const enableWhitelistCall = async (value) => {
    await axios.put(
      `${NGROK}/api/updateAutoGenerateAgent/${profile?.id}?isenabled=${value}`,
    );
  };
  useEffect(() => {
    setEnableDialog(profile?.autoAgentUpgradeEnabled ? true : false);
  }, [profile]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 5,
        }}
      >
        <Typography variant="h5" component="div">
          <strong>Agent Auto Update</strong>
          <Stack direction={"column"} spacing={2} display={"flex"} mt={2}>
            <Typography variant="body1">
              When enabled the agents are automatically upgraded to the latest
              available version.
            </Typography>
          </Stack>
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Switch
            checked={enableDialog}
            onChange={(e) => {
              setEnableDialog(e.target.checked);
              enableWhitelistCall(e.target.checked);
            }}
            sx={{
              alignSelf: "center",
              "& .MuiSvgIcon-root": {
                fontSize: 28,
              },
            }}
          />
          <Typography
            sx={{
              fontSize: 16,
            }}
          >
            Enable
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AutoAgentUpgrade;
