import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { MoreVertical } from "react-feather";
import ReactApexChart from "react-apexcharts";
import { months } from "../../../constants";
const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
`;
const TimeBasedSeries = ({
  data,
  mode,
  customDate,
  customMode,
  fromDetails,
  computerName,
}) => {
  const [auditData, setAuditData] = useState(null);
  const [series, setSeries] = useState(null);
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stackedData, setStackedData] = useState(null);
  const getTimeSeriesData = (data) => {
    let counts = {};

    if (data) {
      const now = new Date();
      let startDate, endDate;

      if (customMode) {
        startDate = new Date(customDate[0]);
        endDate = new Date(customDate[1]);
        counts = countByWeek(startDate, endDate);
      } else {
        switch (mode) {
          case "24Hrs":
            startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            endDate = now;
            counts = countByDate(startDate, endDate);
            break;
          case "lw":
            startDate = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate() - 7,
            );
            startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            endDate = now;
            counts = countByDate(startDate, endDate);
            break;
          case "lm":
            startDate = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate() - 30,
            );
            endDate = now;
            counts = countOnlyByWeek(startDate, endDate);
            break;
          case "l3m":
            startDate = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate() - 90,
            );
            endDate = now;
            counts = countByWeek(startDate, endDate);
            break;
          default:
            console.log("Invalid mode");
            return;
        }
      }
    }
    if (customMode) {
    }
    if (mode === "24Hrs" || mode === "lw") {
      setStackedData(null);

      const labels = Object.keys(counts);

      const sortedLabels = labels.sort((a, b) => new Date(a) - new Date(b));

      var series = [
        {
          name: "",
          data: sortedLabels.map((label) => counts[label]),
        },
      ];

      var options = {
        chart: {
          type: "area",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          selection: {
            enabled: false,
          },
        },

        xaxis: {
          type: "category",
          categories: sortedLabels,
          tickAmount: sortedLabels.length,
          labels: {
            rotate: -45,
            formatter: function (value) {
              return value;
            },
          },
        },

        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number.isInteger(value) ? value : "";
            },
          },
        },
      };

      setSeries(series);
      setOptions(options);
    } else {
      const stackedData = {
        labels: [],
        datasets: [],
      };
      let dataJson = counts;
      if (mode === "lm") {
        stackedData.labels = Object.keys(dataJson);
        const daysInMonth = 7;
        const result = Array.from({ length: daysInMonth }, (_, i) => {
          return Object.values(dataJson).map((week) => {
            if (Object.entries(week)[i]) {
              return Object.entries(week)[i][1];
            } else {
              return 0;
            }
          });
        });
        const frameData = result.map((dataValues) => {
          return {
            label: `Day`,
            data: dataValues,
            backgroundColor: "blue",
            borderColor: "blue",
            borderWidth: 1,
            stack: "Stack 1",
          };
        });
        stackedData.datasets = frameData;
      } else {
        stackedData.labels = Object.keys(dataJson);
        const weeks = Object.keys(dataJson[Object.keys(dataJson)[0]]);
        const datasets = weeks.map((week) => {
          return {
            label: `Week ${week.slice(4)}`,
            data: Object.keys(dataJson).map((month) => dataJson[month][week]),

            borderWidth: 1,
            stack: "Stack 1",
          };
        });
        stackedData.datasets = datasets;
      }

      const getColorGradient = (value, maxValue) => {
        if (maxValue === 0) maxValue = 1;

        const startColor = [18, 20, 129];
        const endColor = [90, 178, 255];

        const color = startColor.map((start, index) => {
          const end = endColor[index];
          const gradient = value / maxValue;
          const channel = start + Math.round((end - start) * gradient);
          return channel < 0 ? 0 : channel > 255 ? 255 : channel;
        });

        return `rgb(${color.join(",")})`;
      };

      const maxValues = stackedData.datasets.map((dataset) =>
        Math.max(...dataset.data),
      );
      const colors = stackedData.datasets.map((dataset, index) => {
        return dataset.data.map((value) => {
          let colors = getColorGradient(value, maxValues[index]);

          return colors;
        });
      });
      stackedData.datasets.map(
        (dataset, index) => (dataset.backgroundColor = colors[index]),
      );

      const options = {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: stackedData.labels,
        },
        fill: {
          opacity: 1,
        },

        tooltip: {
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              return `Week ${seriesIndex + 1}: ${value}`;
            },
          },
        },
        legend: {
          show: false,
        },
        colors: [
          "#1C1678",
          "#0E46A3",
          "#10439F",
          "#387ADF",
          "#387ADF",
          "#008DDA",
          "#50C4ED",
        ],
      };

      setOptions(options);
      setStackedData(stackedData);
    }
    setAuditData(data);
    setLoading(false);
  };

  function countByDate(startDate, endDate) {
    let dateCounts = {};
    let counts = {};
    const filteredData = data.filter((item) => {
      const timestamp = new Date(item.time);
      return timestamp >= startDate && timestamp <= endDate;
    });

    filteredData.forEach((item) => {
      const dateStr = new Date(item.time).toLocaleDateString();
      dateCounts[dateStr] = (dateCounts[dateStr] || 0) + 1;
    });

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dateStr = currentDate.toLocaleDateString();
      if (!dateCounts[dateStr]) {
        dateCounts[dateStr] = 0;
      }
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }
    const sortedCounts = Object.entries(dateCounts).sort((a, b) => {
      return new Date(a[0]) - new Date(b[0]);
    });
    counts = Object.fromEntries(sortedCounts);

    return counts;
  }

  function getWeekNumber(date) {
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    const week1 = new Date(date.getFullYear(), 0, 4);
    let weekNumber =
      1 +
      Math.round(
        ((date - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7,
      );

    let weekNumberVal = Math.round(weekNumber / 7);
    if (weekNumberVal <= 0) {
      weekNumberVal = 1;
    }

    return weekNumberVal;
  }

  const countByWeek = (startDate, endDate) => {
    const result = {};

    let currentDatePointer = new Date(startDate);
    const filteredData = data.filter((item) => {
      const timestamp = new Date(item.time);
      return timestamp >= startDate && timestamp <= endDate;
    });

    while (currentDatePointer <= endDate) {
      const year = currentDatePointer.getFullYear();
      const month = currentDatePointer.toLocaleString("default", {
        month: "short",
      });
      const yearMonth = `${year}-${month}`;
      const weekNumber = getWeek(currentDatePointer);
      const weekKey = `Week${weekNumber}`;

      if (!result[yearMonth]) {
        result[yearMonth] = {};
      }

      result[yearMonth][weekKey] = 0;

      filteredData.forEach((item) => {
        const timestamp = new Date(item.time);
        if (compareDate(timestamp, currentDatePointer)) {
          result[yearMonth][weekKey]++;
        }
      });

      currentDatePointer.setDate(currentDatePointer.getDate() + 1);
    }

    Object.keys(result).forEach((yearMonth) => {
      for (let i = 1; i <= 5; i++) {
        const weekKey = `Week${i}`;
        if (!result[yearMonth][weekKey]) {
          result[yearMonth][weekKey] = 0;
        }
      }
    });

    return result;
  };

  const compareDate = (date1, date2) => {
    const day1 = date1.getDate();
    const month1 = date1.getMonth() + 1;
    const year1 = date1.getFullYear();

    const day2 = date2.getDate();
    const month2 = date2.getMonth() + 1;
    const year2 = date2.getFullYear();

    return day1 === day2 && month1 === month2 && year1 === year2;
  };

  const countOnlyByWeek = (startDate, endDate) => {
    const result = {};
    let currentDatePointer = new Date(startDate);
    const filteredData = data.filter((item) => {
      const timestamp = new Date(item.time);
      return timestamp >= startDate && timestamp <= endDate;
    });

    while (currentDatePointer <= endDate) {
      const year = currentDatePointer.getFullYear();
      const month = currentDatePointer.toLocaleString("default", {
        month: "short",
      });
      const yearMonth = `${year}-${month}`;
      const weekNumber = getWeek(currentDatePointer);
      const weekKey = `Week${weekNumber}`;
      const key = yearMonth + "-" + weekKey;
      if (!result[key]) {
        result[key] = {};
      }

      result[key][currentDatePointer.getDate()] = 0;
      filteredData.forEach((item) => {
        const timestamp = new Date(item.time);
        if (compareDate(timestamp, currentDatePointer)) {
          result[key][currentDatePointer.getDate()]++;
        }
      });

      currentDatePointer.setDate(currentDatePointer.getDate() + 1);
    }
    return result;
  };

  function getWeek(date) {
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOfMonth = date.getDate();
    const firstDayOfWeek = startDate.getDay();
    return Math.ceil((dayOfMonth + firstDayOfWeek) / 7);
  }

  useEffect(() => {
    getTimeSeriesData(data);
  }, [data, mode]);
  useEffect(() => {}, [auditData]);

  return (
    <>
      <Card>
        <CardHeader
          title="Events Timeline"
          action={
            <IconButton aria-label="settings" size="large">
              <MoreVertical />
            </IconButton>
          }
        />
        <CardContent>
          <ChartWrapper>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : mode === "24Hrs" || mode === "lw" ? (
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={350}
              />
            ) : (
              stackedData && (
                <ReactApexChart
                  options={options}
                  series={stackedData.datasets}
                  type="bar"
                  height={350}
                />
              )
            )}
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default TimeBasedSeries;
