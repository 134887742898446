import React, { useEffect, useState } from "react";

import { TabContext, TabList } from "@mui/lab";
import {
  Tab,
  Typography,
  Box,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ActiveDirectorySetup from "./Components/ActiveDirectorySetup";
import EmailNotification from "./Components/EmailNotification";
import { useLocation } from "react-router-dom";
import { myLocalStorage } from "../../../components/StorageHelper";
import TenantSelection from "../Components/TenantSelection";
import AWSSetup from "../auth/components/AWSSetup";
import axios from "axios";
import { NGROK } from "../../../APIs";
import AzureSetup from "./Components/AzureSetup";

const TenantSettings = () => {
  const locationState = useLocation();

  const tenant =
    locationState?.tenant || myLocalStorage.getItem("latestTenant");

  const [tabValue, setTabValue] = useState("generalSettings");
  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [adminAccess, setAdminAccess] = useState("ALL_MACHINES");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const configureAdminSetting = async (e) => {
    setAdminAccess(e.target.value);
    try {
      const response = await axios.post(
        `${NGROK}/api/${selectedTenant?.tenantName}/addgeneralsettings?settingValue=${e.target.value}&settingName=endpointConfig`,
      );
    } catch (err) {
      console.error(err);
    }
  };
  const getAdminConfig = async (name) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenant?.tenantName}/generalsettings`,
      );
      const data = response?.data;
      const configValue = data?.find((config) => config.settingsName === name);

      if (configValue) {
        if (configValue.settingsValue) {
          setAdminAccess(configValue.settingsValue);
        } else {
          setAdminAccess("ALL_MACHINES");
        }
      } else {
        setAdminAccess("ALL_MACHINES");
      }
    } catch (err) {
      console.log(err);
      setAdminAccess("ALL_MACHINES");
    }
  };
  useEffect(() => {
    getAdminConfig("endpointConfig");
  }, [selectedTenant]);

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
        <TabContext value={tabValue}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            display={"flex"}
            justifyContent={"center"}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="General Settings" value={"generalSettings"} />
              <Tab label="AWS Setup" value={"AWSSetup"} />
              <Tab
                label="Active Directory Setup"
                value={"ActiveDirectorySetup"}
              />
              <Tab label="Azure Setup" value={"AzureSetup"} />
            </TabList>
          </Box>
          <CustomTabPanel value={tabValue} index={"AWSSetup"}>
            <AWSSetup />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={"ActiveDirectorySetup"}>
            <ActiveDirectorySetup />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={"generalSettings"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div>
                <Stack spacing={3} sx={{ mb: 5 }}>
                  <Typography variant="h6" gutterBottom>
                    Allow administrator to connect the endpoints
                  </Typography>

                  <RadioGroup
                    aria-label="admin-access"
                    value={adminAccess}
                    onChange={(e) => {
                      configureAdminSetting(e);
                    }}
                  >
                    <FormControlLabel
                      value="ALL_MACHINES"
                      control={<Radio />}
                      label="Access to All machines"
                    />
                    <FormControlLabel
                      value="SERVER_ONLY"
                      control={<Radio />}
                      label="Only server machines"
                    />
                    <FormControlLabel
                      value="NO_ACCESS"
                      control={<Radio />}
                      label="No Access"
                    />
                  </RadioGroup>
                </Stack>
                <Stack spacing={3}>
                  <Typography variant="h6" gutterBottom>
                    Email Notifications
                  </Typography>

                  <EmailNotification tenantName={selectedTenant?.tenantName} />
                </Stack>
              </div>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={"AzureSetup"}>
            <AzureSetup tenantName={selectedTenant?.tenantName} />
          </CustomTabPanel>
        </TabContext>
      </Box>
    </>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TenantSettings;
