import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  Tabs,
  Tab,
  DialogActions,
  Button,
  List,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useUserStore from "../../../services/userStore";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { myLocalStorage } from "../../../components/StorageHelper";
import moment from "moment";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import SyncIcon from "@mui/icons-material/Sync";
import AzureGroupDetails from "./Components/AzureGroupDetails";
import AzureUserDetails from "./Components/AzureUserDetails";

const AzureGroups = () => {
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const userData = useUserStore((state) => state.user);
  const [tenantList, setTenantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [azureGroups, setAzureGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [usersLoading, setUsersLoading] = useState(false);
  const [azureusers, setAzureusers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchUsers, setSearchUsers] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);

  const typographyHeaderStyles = {
    fontSize: "16px",
    display: "inline",
    fontWeight: 600,
  };

  const typographyBodyStyles = { fontSize: "16px", display: "inline" };

  const demandSync = async () => {
    const response = await axios.get(`${NGROK}/api/azure-sync/onDemand`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
  };

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  const handleUserRowClick = (users) => {
    setSelectedUsers(users);
  };

  const handleRowClick = (group) => {
    setSelectedUsers(null);
    setSelectedGroup(group);
    getUsersForTheGroup(group);
    setSearchUsers("");
  };

  const getUsersForTheGroup = async (group) => {
    setUsersLoading(true);
    const response = await axios.get(
      `${NGROK}/api/azure/v1/group-users?groupId=${group.id}`,
    );
    if (response?.data) {
      setAzureusers(response?.data);
    }
    setUsersLoading(false);
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const fetchAzureGroups = async (isFirst = false) => {
    try {
      if (isFirst) {
        setLoading(true);
      }
      const response = await axios.get(
        `${NGROK}/api/azure/v1/groups?tenantName=${selectedTenantName}`,
      );
      if (response?.data) {
        if (isFirst) {
          setSelectedGroup(response?.data[0]);
          getUsersForTheGroup(response?.data[0]);
        }
        setAzureGroups(response?.data);
      }
    } catch (e) {}

    setLoading(false);
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);
  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  useEffect(() => {
    fetchAzureGroups(true);
    const interval = setInterval(() => {
      fetchAzureGroups();
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedTenantName]);

  const filteredGroups = azureGroups.filter((group) =>
    group.displayName.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const filteredUsers = azureusers?.filter((user) =>
    user.displayName.toLowerCase().includes(searchUsers.toLowerCase()),
  );

  return (
    <Box p={2}>
      <Stack direction="column" spacing={5} mb={3}>
        <Typography variant="h5">Azure Groups</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            maxWidth: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {tenantList?.length > 0 && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={selectedTenantName || ""}
                options={tenantList.map((tenant) => tenant.tenantName)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tenant List" />
                )}
                onChange={(e, value) => {
                  handleTenantChange(value);
                }}
              />
            )}
          </Box>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
            onClick={() => {
              demandSync();
            }}
          >
            <SyncIcon />
            Sync
          </Button>
        </Box>
        <Stack direction="row" spacing={5}>
          <Box width="30%">
            <TableContainer component={Paper}>
              <Table
                sx={{
                  height: "fit-content",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "16px",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow>
                    <MyHeaderTableCell
                      sx={{ fontSize: 18 }}
                      align={"center"}
                      className={"userName"}
                    >
                      Groups
                    </MyHeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <TextField
                        variant="outlined"
                        placeholder="Search groups..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{ width: "100%" }}
                      />
                    </TableCell>
                  </TableRow>
                  {filteredGroups.length > 0 ? (
                    filteredGroups.map((group, index) => (
                      <TableRow
                        selected={group.id === selectedGroup.id}
                        key={index}
                        onClick={() => handleRowClick(group)}
                        style={{ cursor: "pointer" }}
                        component="td"
                        scope="row"
                        className="groupRowName userName"
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                            cursor: "pointer",
                          },
                          "& td, & th": {
                            border: "1px solid #233044",
                            fontSize: "16px",
                          },
                          "&.Mui-selected": {
                            background: "#233044 !important",
                            "& td, & th": {
                              color: "#fff",
                            },
                          },
                        }}
                      >
                        <TableCell>
                          <Typography variant="body1" sx={{ fontSize: "16px" }}>
                            <GroupIcon sx={{ marginRight: 2 }} />
                            {group.displayName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="groupRow">
                      <TableCell
                        component="td"
                        scope="row"
                        className="groupRowName userName"
                        align="center"
                        colSpan={5}
                      >
                        <Typography variant="body1">No groups found</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box width="30%">
            {selectedGroup && (
              <>
                <Box
                  sx={{
                    border: "1px solid",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      background: "#233044",
                      color: "#fff",
                      padding: "10px",
                      borderRadius: "5px",
                      fontSize: "18px",
                      p: 4.8,
                    }}
                  >
                    Details
                  </Typography>

                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="Groups and Users Tabs"
                    sx={{ fontSize: 16 }}
                  >
                    <Tab
                      label="Groups"
                      icon={<GroupIcon />}
                      iconPosition="start"
                      sx={{ fontSize: 16 }}
                    />
                    <Tab
                      label={`Users${azureusers && azureusers.length > 0 ? ` (${azureusers.length})` : ""}`}
                      icon={<PersonIcon />}
                      iconPosition="start"
                      sx={{ fontSize: 16 }}
                    />
                  </Tabs>

                  {selectedTab === 0 && (
                    <Box>
                      {selectedGroup ? (
                        <AzureGroupDetails group={selectedGroup} />
                      ) : (
                        <Typography sx={{ fontSize: 16 }}>
                          No group selected
                        </Typography>
                      )}
                    </Box>
                  )}

                  {selectedTab === 1 && (
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          height: "fit-content",
                          "& th": {
                            background: "#233044",
                            color: "#fff",
                          },
                          "& td, & th": {
                            fontSize: "16px",
                          },
                        }}
                        size="large"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <TextField
                                label="Search Users"
                                variant="outlined"
                                fullWidth
                                value={searchUsers}
                                onChange={(e) => setSearchUsers(e.target.value)}
                                sx={{ marginBottom: 2 }}
                              />
                            </TableCell>
                          </TableRow>
                          {filteredUsers.length === 0 ? (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: 12,
                                  }}
                                >
                                  No User found
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ) : (
                            filteredUsers.map((user, index) => (
                              <TableRow
                                selected={selectedUsers?.id === user?.id}
                                key={index}
                                onClick={() => handleUserRowClick(user)}
                                style={{ cursor: "pointer" }}
                                sx={{
                                  "&:hover": {
                                    background: "#f5f5f5",
                                    cursor: "pointer",
                                  },
                                  "& td, & th": {
                                    fontSize: "16px",
                                  },
                                  "&.Mui-selected": {
                                    background: "#233044 !important",
                                    "& td, & th": {
                                      color: "#fff",
                                    },
                                  },
                                }}
                              >
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontSize: "16px" }}
                                  >
                                    <Stack
                                      alignItems={"center"}
                                      direction={"row"}
                                    >
                                      <PersonIcon sx={{ marginRight: 2 }} />
                                      <Typography variant="body1" fontSize={16}>
                                        {" "}
                                        {user.displayName}
                                      </Typography>
                                    </Stack>
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              </>
            )}
          </Box>

          <Box width="30%">
            {selectedUsers ? (
              <Box
                sx={{
                  border: "1px solid",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    background: "#233044",
                    color: "#fff",
                    padding: "10px",
                    borderRadius: "5px",
                    fontSize: "18px",
                  }}
                >
                  Details of {selectedUsers.displayName}
                </Typography>

                <AzureUserDetails user={selectedUsers} />
              </Box>
            ) : null}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AzureGroups;
