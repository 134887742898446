import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Stack,
  CircularProgress,
  Box,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import Paper from "@mui/material/Paper";
import { NGROK } from "../../../../APIs";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Logo from "../../../../vendor/Whiteswan-Security-logo.png";

const TablePdf = ({
  selectedProfile,
  selectedTenant,
  activeUsersList,
  activeDevicesList,
  activeResourceType,
}) => {
  const [reportLoading, setReportLoading] = useState({
    application: false,
    folder: false,
    group: false,
  });

  const [deviceWithReports, setDeviceWithReports] = useState([]);

  const getAllApplications = async (userId, deviceId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/computer-user/get-all-apps?userId=${userId}&computerId=${deviceId}`,
      );

      const apps = response.data.content
        .filter((item) => item && item?.computerUsersPublishersAppsMembership)
        .map((item) => item?.computerUsersPublishersAppsMembership);

      return apps;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getFolders = async (deviceId, userId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenant.tenantName}/computers/${deviceId}/computer-users/${userId}/folders`,
      );
      return response.data.content;
    } catch (error) {
      console.error(error, "error");
      return [];
    }
  };

  const getUserGroups = async (userId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenant.tenantName}/computer-user-groups/membership/${userId}`,
      );
      return response.data.content;
    } catch (error) {
      console.error(error, "error");
      return [];
    }
  };

  const handleGeneratePDF = async (resource) => {
    const pdf = new jsPDF();
    const title = "Reports";
    const titleWidth = pdf.getTextWidth(title);
    const pageWidth = pdf.getPageWidth();
    const titleX = (pageWidth - titleWidth) / 2;

    const imgX = (pageWidth - 100) / 2;

    pdf.setFillColor(35, 48, 68);
    pdf.rect(0, 0, pdf.internal.pageSize.width, 40, "F");

    pdf.addImage(Logo, "png", imgX, 5, 100, 30);

    let startY = 50;
    let contentAdded = false;
    let computerUser = "";
    deviceWithReports.forEach((device) => {
      device.users.forEach((user, index) => {
        computerUser = user.cn;
        const userTableData =
          resource === "Application" && user.hasOwnProperty("applications")
            ? [
                ...user.applications.map((app, index) => [
                  index + 1,
                  app.appName,
                  device.dNSHostName,
                  user.cn,
                ]),
              ]
            : resource === "Folder" && user.hasOwnProperty("folders")
              ? [
                  ...user.folders?.map((folder, index) => [
                    index + 1,
                    folder.path,
                    device.dNSHostName,
                    user.cn,
                  ]),
                ]
              : resource === "Privilege" && user.hasOwnProperty("groups")
                ? [
                    ...user.groups.map((group, index) => [
                      index + 1,
                      group.adComputerUserGroup.cn,
                      device.dNSHostName,
                      user.cn,
                    ]),
                  ]
                : [];

        if (userTableData.length > 0) {
          startY = index === 0 ? 50 : 10;

          pdf.text("Reports", titleX, startY);
          pdf.text(
            `Selected tenant: ${selectedTenant.tenantName}`,
            10,
            startY + 10,
          );
          pdf.text(`Resource type: ${resource}`, 10, startY + 20);
          pdf.text(
            `Over-provisioned current profile: ${device.profile}`,
            10,
            startY + 30,
          );
          pdf.text(`New profile: ${selectedProfile.name}`, 10, startY + 40);
          pdf.text(`Computer User: ${computerUser}`, 10, startY + 50);
          pdf.text(`Over-provisioned resources`, 70, startY + 60);

          autoTable(pdf, {
            startY: startY + 70,
            head: [["ID", "Resource", "Device", "User"]],
            body: userTableData,
            headStyles: { fillColor: [35, 48, 68] },
          });

          contentAdded = true;
          if (index < device.users.length - 1) {
            pdf.addPage();
          }
        }
      });
    });

    if (!contentAdded) {
      pdf.text("Reports", titleX, startY);
      pdf.text(
        `Selected tenant: ${selectedTenant.tenantName}`,
        10,
        startY + 10,
      );
      pdf.text(`Resource type: ${resource}`, 10, startY + 20);

      autoTable(pdf, {
        startY: startY + 50,
        head: [["ID", "Resource", "Device", "User"]],
        body: [["", "No data available", "", ""]],
        headStyles: { fillColor: [35, 48, 68] },
        bodyStyles: { halign: "center", valign: "middle" },
      });
    }

    pdf.save("Reports.pdf");
  };

  const getAllApplicationsWithPagination = async (profileId) => {
    let allApplications = [];
    try {
      const countResponse = await axios.get(
        `${NGROK}/api/profiles/get-all-apps/count?profileId=${profileId}&profileResource=BLOCKED`,
      );
      const totalCount = countResponse.data;
      console.log(totalCount);

      const limit = 1000;
      const totalPages = Math.ceil(totalCount / limit);

      let pageCount = 0;

      while (pageCount <= totalPages) {
        try {
          const response = await axios.get(
            `${NGROK}/api/profiles/get-all-apps?profileId=${profileId}&page=${pageCount}&limit=${limit}&profileResource=BLOCKED`,
          );

          const profileApps = response.data
            .filter((item) => item && item?.defaultProfileAppsResource)
            .map((item) => item?.defaultProfileAppsResource);

          console.log(profileApps);

          if (Array.isArray(profileApps)) {
            allApplications = [...allApplications, ...profileApps];
          } else if (profileApps) {
            allApplications.push(profileApps);
          }
        } catch (e) {
          console.error(e);
        }

        pageCount++;
      }
    } catch (error) {
      console.error("Error fetching applications with pagination:", error);
    }
    return allApplications;
  };

  const getProfileApplications = async (profileId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/profiles/get-all-apps?profileId=${profileId}`,
      );

      return response.data?.defaultProfileAppsResource;
    } catch (error) {
      console.error(error);
    }
  };

  const getProfileFolders = async (profile) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${profile.os}/profiles/${profile.id}/folders`,
      );
      return response.data.content;
    } catch (error) {
      console.error(error);
    }
  };

  const getProfileGroups = async (profile) => {
    const response = await axios.get(
      `${NGROK}/api/${profile.os}/profiles/${profile.id}/membership`,
    );
    return response.data.content;
  };

  const fetchData = async () => {
    const appDevices = [];
    const folderDevices = [];
    const groupDevices = [];
    for (const type of activeResourceType) {
      switch (type) {
        case "Application":
          const checkDifferencesInApps = async () => {
            setReportLoading((prev) => ({ ...prev, application: true }));

            const [devicesWithYesResources, noProfileResource] =
              await Promise.all([
                await Promise.all(
                  activeDevicesList.map(async (device) => {
                    const usersWithApps = await Promise.all(
                      activeUsersList.map(async (user) => {
                        const allApplications = await getAllApplications(
                          user.id,
                          device.id,
                        );

                        const applications = allApplications?.filter(
                          (app) =>
                            app.groupStatus === "ENABLED" ||
                            app.inheritedGroupStatus === "ENABLED",
                        );
                        return { ...user, applications };
                      }),
                    );
                    return { ...device, users: usersWithApps };
                  }),
                ),
                await getAllApplicationsWithPagination(
                  selectedProfile.id,
                  "BLOCKED",
                ),
              ]);

            const matchedDevices = devicesWithYesResources
              .map((device) => {
                const matchedUsers = device.users
                  .map((user) => {
                    const matchedApplications = user.applications?.filter(
                      (app) =>
                        noProfileResource.some(
                          (noApp) => noApp.appId === app.appId,
                        ),
                    );
                    return { ...user, applications: matchedApplications };
                  })
                  .filter((user) => user.applications?.length > 0);

                return {
                  ...device,
                  users: matchedUsers,
                };
              })
              .filter((device) => device.users?.length > 0); // Only keep devices with matched users

            setReportLoading((prev) => ({ ...prev, application: false }));

            return matchedDevices;
          };

          try {
            const devices = await checkDifferencesInApps();
            appDevices.push(...devices);
          } catch (error) {
            console.error(error, "Error during app comparison");
          }
          break;
        case "Folder":
          const checkDifferencesInFolders = async () => {
            setReportLoading((prev) => ({ ...prev, folder: true }));
            const devicesWithYesFolders = await Promise.all(
              activeDevicesList.map(async (device) => {
                const usersWithFolders = await Promise.all(
                  activeUsersList.map(async (user) => {
                    const allFolders = await getFolders(device.id, user.id);
                    const folders = allFolders?.filter(
                      (folder) =>
                        folder.groupStatus === "ENABLED" ||
                        folder.inheritedGroupStatus === "ENABLED",
                    );
                    return { ...user, folders };
                  }),
                );
                return { ...device, users: usersWithFolders };
              }),
            );

            const profileFolders = await getProfileFolders(selectedProfile);

            const noProfileResource = profileFolders.filter(
              (folder) => folder.profileResource === "BLOCKED",
            );

            const matchedDevices = devicesWithYesFolders
              .map((device) => {
                const matchedUsers = device.users
                  .map((user) => {
                    const matchedFolders = user.folders?.filter((yesFolder) =>
                      noProfileResource.some(
                        (noFolder) => noFolder.folderId === yesFolder.folderId,
                      ),
                    );
                    return { ...user, folders: matchedFolders };
                  })
                  .filter((user) => user.folders.length > 0);
                return {
                  ...device,
                  users: matchedUsers,
                };
              })
              .filter((device) => device.users.length > 0);
            setReportLoading((prev) => ({ ...prev, folder: false }));
            return matchedDevices;
          };
          try {
            const devices = await checkDifferencesInFolders();
            folderDevices.push(...devices);
          } catch (error) {
            console.error(error, "error");
          }

          break;
        case "Privilege":
          const checkDifferencesInGroups = async () => {
            setReportLoading((prev) => ({ ...prev, group: true }));

            const devicesWithYesGroups = await Promise.all(
              activeDevicesList.map(async (device) => {
                const usersWithGroups = await Promise.all(
                  activeUsersList.map(async (user) => {
                    const allGroups = await getUserGroups(user.id);
                    const groups = allGroups?.filter(
                      (group) =>
                        group.groupStatus === "ENABLED" ||
                        group.inheritedGroupStatus === "ENABLED",
                    );
                    return { ...user, groups };
                  }),
                );
                return { ...device, users: usersWithGroups };
              }),
            );

            //get profile groups
            const profileGroups = await getProfileGroups(selectedProfile);

            const noProfileResource = profileGroups.filter(
              (group) => group.profileResource === "BLOCKED",
            );

            const matchedDevices = devicesWithYesGroups
              .map((device) => {
                const matchedUsers = device.users
                  .map((user) => {
                    const matchedGroups = user.groups?.filter((yesGroup) =>
                      noProfileResource.some(
                        (noGroup) =>
                          noGroup.groupId === yesGroup.adComputerUserGroup.id,
                      ),
                    );
                    return { ...user, groups: matchedGroups };
                  })
                  .filter((user) => user.groups.length > 0);
                return {
                  ...device,
                  users: matchedUsers,
                };
              })
              .filter((device) => device.users.length > 0);

            setReportLoading((prev) => ({ ...prev, group: false }));
            return matchedDevices;
          };
          try {
            const devices = await checkDifferencesInGroups();
            groupDevices.push(...devices);
          } catch (error) {
            console.error(error, "error");
          }
          break;
        default:
          console.log("default");
      }
    }
    return { appDevices, folderDevices, groupDevices };
  };

  const unifyDevicesArrays = (devices) => {
    const allDevicesMap = {};

    devices.forEach((device) => {
      if (!allDevicesMap[device.id]) {
        allDevicesMap[device.id] = {
          ...device,
          users: {
            ...device.users.reduce(
              (acc, user) => ({ ...acc, [user.id]: user }),
              {},
            ),
          },
        };
      } else {
        const existingDevice = allDevicesMap[device.id];
        device.users.forEach((user) => {
          if (existingDevice.users[user.id]) {
            existingDevice.users[user.id] = {
              ...existingDevice.users[user.id],
              ...user,
            };
          } else {
            existingDevice.users[user.id] = user;
          }
        });
      }
    });

    const combinedDevices = Object.values(allDevicesMap).map((device) => ({
      ...device,
      users: Object.values(device.users).sort((a, b) => a.id - b.id),
    }));

    // Сортуємо пристрої за id
    combinedDevices.sort((a, b) => a.id - b.id);

    return combinedDevices;
  };

  useEffect(() => {
    const fetchDataAndContinue = async () => {
      const { appDevices, folderDevices, groupDevices } = await fetchData();
      const unitedDevices = unifyDevicesArrays([
        ...appDevices,
        ...folderDevices,
        ...groupDevices,
      ]);
      setDeviceWithReports(unitedDevices);
    };
    setReportLoading(true);
    fetchDataAndContinue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeResourceType]);

  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
  }, [selectedProfile]);

  return (
    <Stack spacing={3} direction={"row"}>
      {activeResourceType.length
        ? activeResourceType.map((resource, i) => (
            <Stack spacing={3} key={resource}>
              <Button
                onClick={() => handleGeneratePDF(resource)}
                sx={{ width: "140px" }}
                variant="outlined"
              >
                {`Generate ${resource} PDF`}
              </Button>

              <TableContainer
                component={Paper}
                sx={{
                  minWidth: 150,
                  maxWidth: 850,
                  height: "fit-content",
                }}
              >
                <Table key={uuidv4()} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>{resource}</TableCell>
                      <TableCell>Device</TableCell>
                      <TableCell>User</TableCell>
                    </TableRow>
                  </TableHead>
                  {deviceWithReports.length ? (
                    deviceWithReports.map((device) =>
                      device.users.map((user) => (
                        <TableBody key={uuidv4()}>
                          {resource === "Privilege" &&
                          user.hasOwnProperty("groups") &&
                          user.groups.length
                            ? user.groups.map((group, index) => (
                                <TableRow
                                  key={uuidv4()}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    {group.adComputerUserGroup.cn}
                                  </TableCell>
                                  <TableCell>{device.dNSHostName}</TableCell>
                                  <TableCell>{user.cn}</TableCell>
                                </TableRow>
                              ))
                            : null}
                          {resource === "Folder" &&
                          user.hasOwnProperty("folders") &&
                          user.folders.length
                            ? user.folders.map((folder, index) => (
                                <TableRow
                                  key={uuidv4()}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{folder.path}</TableCell>
                                  <TableCell>{device.dNSHostName}</TableCell>
                                  <TableCell>{user.cn}</TableCell>
                                </TableRow>
                              ))
                            : null}
                          {resource === "Application" &&
                          user.hasOwnProperty("applications") &&
                          user.applications.length
                            ? user.applications.map((application, index) => (
                                <TableRow
                                  key={uuidv4()}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell
                                    sx={{
                                      wordWrap: "break-word",
                                      maxWidth: "220px",
                                    }}
                                  >
                                    {application.appName}
                                  </TableCell>
                                  <TableCell>{device.dNSHostName}</TableCell>
                                  <TableCell>{user.cn}</TableCell>
                                </TableRow>
                              ))
                            : null}
                        </TableBody>
                      )),
                    )
                  ) : reportLoading.folder ||
                    reportLoading.application ||
                    reportLoading.group ? (
                    <Box display={"flex"} p={5}>
                      <CircularProgress />
                    </Box>
                  ) : null}
                </Table>
              </TableContainer>
            </Stack>
          ))
        : null}
    </Stack>
  );
};

export default TablePdf;
