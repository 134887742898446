import React, { useState, useEffect } from "react";

import "../pages.scss";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ProfileApplicationsControl from "./ProfileApplicationsControl";
import ProfileDataAcces from "./ProfileDataAcces";
import ProfilePrivilegeManagement from "./ProfilePrivilegeManagement";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import { useLocation } from "react-router-dom";
import ProfileUrlControl from "./ProfileUrlControl";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";

const ProfileResources = () => {
  const location = useLocation();
  const locationState = location?.state;

  const [selectedResourceId, setSelectedResourcesId] = useState(
    myLocalStorage.getItem("selectedProfileResourceId") || 1,
  );
  const [showCount, setShowCount] = useState(50);

  const [groupsSearchValues, setGroupsSearchValues] = useState("");
  const [foldersSearchValues, setFoldersSearchValues] = useState("");

  const userData = useUserStore((state) => state.user);
  const lastSession = useUserStore((state) => state.lastSession);
  const setLastSession = useUserStore((state) => state.setLastSession);

  const selectedProfile =
    locationState?.defaultProfile || myLocalStorage.getItem("selectedProfile");

  const tenant =
    locationState?.selectedTenant || myLocalStorage.getItem("latestTenant");

  useEffect(() => {
    myLocalStorage.setItem("selectedProfile", selectedProfile);
  }, [selectedProfile]);

  useEffect(() => {
    myLocalStorage.setItem("selectedProfileResourceId", selectedResourceId);
    setFoldersSearchValues("");
    setGroupsSearchValues("");
  }, [selectedResourceId]);

  return (
    <section className="domainTab">
      <Resources
        selectedResourceId={selectedResourceId}
        setSelectedResourcesId={setSelectedResourcesId}
        lastSession={lastSession}
        setLastSession={setLastSession}
      />

      {selectedResourceId === 1 && (
        <ProfileApplicationsControl
          userData={userData}
          selectedProfile={selectedProfile}
          showCount={showCount}
          setShowCount={setShowCount}
          tenantName={tenant.tenantName}
        />
      )}
      {selectedResourceId === 2 && (
        <ProfileDataAcces
          userData={userData}
          selectedProfile={selectedProfile}
          showCount={showCount}
          setShowCount={setShowCount}
          foldersSearchValues={foldersSearchValues}
          setFoldersSearchValues={setFoldersSearchValues}
          tenantName={tenant.tenantName}
        />
      )}
      {selectedResourceId === 3 && (
        <ProfilePrivilegeManagement
          selectedProfile={selectedProfile}
          userData={userData}
          showCount={showCount}
          setShowCount={setShowCount}
          setGroupsSearchValues={setGroupsSearchValues}
          groupsSearchValues={groupsSearchValues}
          tenantName={tenant.tenantName}
        />
      )}
      {selectedResourceId === 4 && (
        <ProfileUrlControl
          selectedProfile={selectedProfile}
          showCount={showCount}
          setShowCount={setShowCount}
          tenantName={tenant.tenantName}
        />
      )}
    </section>
  );
};

const Resources = ({
  selectedResourceId,
  setSelectedResourcesId,
  lastSession,
  setLastSession,
}) => {
  const resources = [
    {
      id: 1,
      title: "Application Control",
      icon: <AppsOutlinedIcon />,
    },
    {
      id: 2,
      title: "Data Access",
      icon: <TextSnippetOutlinedIcon />,
    },
    {
      id: 3,
      title: "Privilege Management",
      icon: <SecurityOutlinedIcon />,
    },
    {
      id: 4,
      title: "URL Control",
      icon: <LinkOutlinedIcon />,
    },
  ];

  return (
    <section>
      <TableContainer style={{ height: "fit-content" }} component={Paper}>
        <Table
          sx={{
            width: "100%",
            border: "1px solid #233044",
            tableLayout: "fixed",
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{ color: "white", backgroundColor: "#233044" }}
                className="userName"
              >
                Resources
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource) => (
              <TableRow
                className={
                  resource.id === selectedResourceId
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                key={resource.id}
                sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                onClick={() => {
                  setSelectedResourcesId(resource.id);
                  setLastSession({
                    ...lastSession,
                    resourceId: resource.id,
                  });
                }}
              >
                <TableCell
                  component="td"
                  scope="row"
                  className="userRowName userName"
                  title={resource.title}
                >
                  <Stack spacing={1} direction={"row"}>
                    {resource.icon}
                    <span>{resource.title}</span>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};
export default ProfileResources;
