import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { FormGroup } from "@mui/material";
import TablePdf from "./components/TablePdf";
import TenantSelection from "../Components/TenantSelection";
import DeviceSelection from "./components/DeviceSelection";
import UserSelection from "./components/UserSelection";
import ResourceTypeSelection from "./components/ResourceTypeSelection";
import SelectOS from "../Components/SelectOS";
import ProfileSelection from "./components/ProfileSelection";
import { myLocalStorage } from "../../../components/StorageHelper";

const steps = [
  {
    label: `Select tenant`,
    description: ``,
  },
  {
    label: `Select OS`,
    description: ``,
  },
  {
    label: "Select devices",
    description: "",
  },
  {
    label: "Select users",
    description: "",
  },
  {
    label: "Select resource type",
    description: ``,
  },
  {
    label: "Select profile to compare",
    description: "",
  },
];

const OverProvisioning = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTenant, setSelectedTenant] = useState(
    myLocalStorage.getItem("latestTenant"),
  );
  const [activeDevicesList, setActiveDevicesList] = useState([]);
  const [activeUsersList, setActiveUsersList] = useState([]);

  const [activeResourceType, setActiveResourceType] = useState([]);
  const [selectedOS, setSelectedOS] = useState("");

  const [selectedProfile, setSelectedProfile] = useState(null);

  const [stepsCompleted, setStepsCompleted] = useState(false);

  const handleNext = () => {
    if (activeStep === 0 && selectedTenant) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 2 && activeDevicesList.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 3 && activeUsersList.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 4 && activeResourceType.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleFinish = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setStepsCompleted(true);
  };

  const handleBack = () => {
    if (stepsCompleted) setStepsCompleted(false);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    if (activeStep === 2) setActiveDevicesList([]);
    if (activeStep === 3) {
      setActiveDevicesList([]);
      setActiveUsersList([]);
      setActiveResourceType([]);
    } else if (activeStep === 4) {
      setActiveUsersList([]);
      setActiveResourceType([]);
    } else if (activeStep === 5) setActiveResourceType([]);
  };

  const handleReset = () => {
    if (stepsCompleted) setStepsCompleted(false);
    setActiveDevicesList([]);
    setActiveUsersList([]);
    setActiveResourceType([]);
    setSelectedProfile(null);

    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 0 && selectedTenant ? (
                  <Typography color={"black"} fontSize={15}>
                    {selectedTenant.tenantName}
                  </Typography>
                ) : index === 1 ? (
                  <Typography color={"black"} fontSize={15}>
                    {selectedOS}
                  </Typography>
                ) : index === 2 && activeDevicesList.length ? (
                  <Typography color={"black"} fontSize={15}>
                    {activeDevicesList.map(
                      (device) => `${device.dNSHostName}, `,
                    )}
                  </Typography>
                ) : index === 3 && activeUsersList.length ? (
                  <Typography color={"black"} fontSize={15}>
                    {activeUsersList.map((user) => `${user.cn}, `)}
                  </Typography>
                ) : index === 4 && activeResourceType.length ? (
                  <Typography color={"black"} fontSize={15}>
                    {activeResourceType.map((resource) => `${resource}, `)}
                  </Typography>
                ) : index === 5 ? (
                  <Typography color={"black"} fontSize={15}>
                    {selectedProfile?.name}
                  </Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <FormGroup row>
                {activeStep === 0 ? (
                  <TenantSelection
                    selectedTenant={selectedTenant}
                    setSelectedTenant={setSelectedTenant}
                  />
                ) : activeStep === 1 ? (
                  <SelectOS
                    selectedOS={selectedOS}
                    setSelectedOS={setSelectedOS}
                  />
                ) : activeStep === 2 ? (
                  <DeviceSelection
                    os={selectedOS}
                    selectedTenant={selectedTenant}
                    activeDevicesList={activeDevicesList}
                    setActiveDevicesList={setActiveDevicesList}
                  />
                ) : activeStep === 3 ? (
                  <UserSelection
                    activeDevicesList={activeDevicesList}
                    setActiveUsersList={setActiveUsersList}
                    activeUsersList={activeUsersList}
                    selectedTenantName={selectedTenant?.tenantName}
                  />
                ) : activeStep === 4 ? (
                  <ResourceTypeSelection
                    activeResourceType={activeResourceType}
                    setActiveResourceType={setActiveResourceType}
                  />
                ) : activeStep === 5 ? (
                  <ProfileSelection
                    os={selectedOS}
                    selectedTenant={selectedTenant}
                    setSelectedProfile={setSelectedProfile}
                    selectedProfile={selectedProfile}
                  />
                ) : null}
              </FormGroup>

              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    disabled={
                      (activeStep === 0 && !selectedTenant) ||
                      (activeStep === 2 && !activeDevicesList.length) ||
                      (activeStep === 3 && !activeUsersList.length) ||
                      (activeStep === 4 && !activeResourceType.length)
                    }
                    onClick={
                      index === steps.length - 1 ? handleFinish : handleNext
                    }
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Box sx={{ p: 3 }}>
          <Typography fontSize={16}>All steps completed</Typography>
          <Button
            size="large"
            variant="contained"
            onClick={handleReset}
            sx={{ mt: 1, mr: 1 }}
          >
            Reset
          </Button>
        </Box>
      )}
      {stepsCompleted ? (
        <TablePdf
          selectedProfile={selectedProfile}
          selectedTenant={selectedTenant}
          activeUsersList={activeUsersList}
          activeDevicesList={activeDevicesList}
          activeResourceType={activeResourceType}
        />
      ) : null}
    </Box>
  );
};

export default OverProvisioning;
