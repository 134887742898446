import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  TableHead,
} from "@mui/material";
import WhiteListHashesDetails from "./WhiteListHashesDetails";
import AutoAgentUpgrade from "./AutoAgentUpgrade";

const ProfileSettings = () => {
  const [selectedOption, setSelectedOption] = useState("whitelisting");
  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={10}>
        <Grid item xs={3}>
          <TableContainer component={Paper}>
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                },
                "& td, & th": {
                  border: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    Settings
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  hover
                  onClick={() => setSelectedOption("agentautoupdate")}
                  selected={selectedOption === "agentautoupdate"}
                  sx={{
                    "&:hover": {
                      background: "#f5f5f5",
                      cursor: "pointer",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "8px",
                    },
                    "&.Mui-selected": {
                      background: "#233044 !important",
                      "& td, & th": {
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <TableCell>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: 18,
                      }}
                    >
                      Agent Auto Update
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  hover
                  onClick={() => setSelectedOption("whitelisting")}
                  selected={selectedOption === "whitelisting"}
                  sx={{
                    "&:hover": {
                      background: "#f5f5f5",
                      cursor: "pointer",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "8px",
                    },
                    "&.Mui-selected": {
                      background: "#233044 !important",
                      "& td, & th": {
                        color: "#fff",
                      },
                    },
                  }}
                >
                  <TableCell>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: 18,
                      }}
                    >
                      Whitelisting Hashes
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={9}>
          {selectedOption === "whitelisting" && <WhiteListHashesDetails />}
          {selectedOption === "agentautoupdate" && <AutoAgentUpgrade />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileSettings;
