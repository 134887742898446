import axios from "axios";
import { NGROK } from "../../../APIs";
import UrlFetchManager from "../../../services/UrlFetchManager";
import { myLocalStorage } from "../../../components/StorageHelper";

export const fetchNotifications = async (
  setter,
  fromdetails = false,
  computerName = null,
  email,
  tenantName,
  controller = new AbortController(),
) => {
  try {
    if (fromdetails && !tenantName) {
      const tenant = myLocalStorage.getItem("latestTenant");
      tenantName = tenant?.tenantName;
    }

    if (tenantName && email) {
      const response = await UrlFetchManager(
        `${NGROK}/api/access/admin-notifications?email=${email}&tenantName=${tenantName}`,
        { signal: controller.signal },
      );

      let filteredData = response.data;

      setter(filteredData);
    }
  } catch (error) {
    console.error(error);
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error(error);
    }
  }
};
