import { List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const AzureDeviceItem = ({ device }) => {
  return (
    <>
      <List>
        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Name:</strong> {device.displayName}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Operating System:</strong> {device.operatingSystem}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Operating System Version:</strong>{" "}
                {device.operatingSystemVersion}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Registration Time:</strong>{" "}
                {moment(device.azureRegistrationDateTime).format(
                  "DD/MM/YYYY HH:mm:ss",
                )}
              </Typography>
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={
              <Typography sx={{ fontSize: 16 }}>
                <strong>Synced At:</strong>{" "}
                {moment(device.syncedAt).format("DD/MM/YYYY HH:mm:ss")}
              </Typography>
            }
          />
        </ListItem>
      </List>
    </>
  );
};

export default AzureDeviceItem;
